import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './StadaPage.css';

import Filter from '../components/Filter/filter';
import Tolfraedi from '../components/Tolfraedi/Tolfraedi';


const TolfraediPage = () => {

    const dispatch = useDispatch();
    const states = useSelector(state => state);

    

    return (
        <div id="container">
            <div class="home-content">
                <h1>Tölfræði</h1>
                <Filter filters="deild" flokkar="flokkar"/>
                <Tolfraedi/>
            </div>
        </div>
    )
}

export default TolfraediPage;