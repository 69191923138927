import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarkahaestu } from '../../actions/getMarkahaestu';

import './Tolfraedi.css';


const Tolfraedi = () => {

    const [gulspjold, setGulspjold] = useState({});
    const [raudspjold, setRaudspjold] = useState({});
    const states = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch(`https://bestadeildin.is/API/markahaestu/${states.deild}`, {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                dispatch(getMarkahaestu(result.MotMarkahaestuSvar.ArrayMotMarkahaestu.MotMarkahaestu));
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild, states.selectedFlokkur]);

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch(`https://bestadeildin.is/API/gulspjold/${states.deild}`, {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                //dispatch(getMarkahaestu(result.MotMarkahaestuSvar.ArrayMotMarkahaestu.MotMarkahaestu));
                if(Object.keys(result.MotSpjoldSvar.ArrayMotSpjold).length == 0) {
                    setGulspjold([]);
                } else if(!Array.isArray(result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold)) {
                    setGulspjold([result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold]);
                } else {
                    setGulspjold(result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild, states.selectedFlokkur]);

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch(`https://bestadeildin.is/API/raudspjold/${states.deild}`, {
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(Object.keys(result.MotSpjoldSvar.ArrayMotSpjold).length == 0) {
                    setRaudspjold([]);
                } else if(!Array.isArray(result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold)) {
                    setRaudspjold([result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold]);
                } else {
                    setRaudspjold(result.MotSpjoldSvar.ArrayMotSpjold.MotSpjold);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild, states.selectedFlokkur]);

    const accentsTidy = (s) => {
        var r=s.toLowerCase();
        r = r.replace(new RegExp(/\s/g),"");
        r = r.replace(new RegExp(/[àáâãäå]/g),"a");
        r = r.replace(new RegExp(/æ/g),"ae");
        r = r.replace(new RegExp(/ç/g),"c");
        r = r.replace(new RegExp(/[èéêë]/g),"e");
        r = r.replace(new RegExp(/[ìíîï]/g),"i");
        r = r.replace(new RegExp(/ñ/g),"n");
        r = r.replace(new RegExp(/ð/g),"d");              
        r = r.replace(new RegExp(/[òóôõö]/g),"o");
        r = r.replace(new RegExp(/œ/g),"oe");
        r = r.replace(new RegExp(/þ/g),"th");
        r = r.replace(new RegExp(/[ùúûü]/g),"u");
        r = r.replace(new RegExp(/[ýÿ]/g),"y");
        r = r.replace(new RegExp(/\W/g),"");
        return r;
    };

    

    if(states.markahaestu && Object.keys(states.markahaestu).length === 0 && Object.getPrototypeOf(states.markahaestu) === Object.prototype) {
        return (
            <div></div>
        )
    } else {
        return (
            <div class="umferd-cont">
                {states.selectedFlokkur == "Markahæstu" ?
                    <div>
                        <div class="col-names">
                            <p class="pos hide-mobile">Staða</p>
                            <p class="lid-imgText-tol">Leikmaður</p>
                            <p class="played-tol">Mörk</p>
                            <p class="played-tol">Víti</p>
                            <p class="played-tol">Leikir</p>
                        </div>
                        <div class="stada-container">
                            {states.markahaestu.map((i, j) => (
                                <div class="lid-cont">
                                    <p class="pos">{j+1}</p>
                                    <div class="lid-imgText-tol">
                                        <img src={"https://bestadeildin.is/teamlogos/" + accentsTidy(i.FelagNafn.replaceAll(".","").replaceAll(" ", "_")) + ".png"}/>
                                        <p>{i.LeikmadurNafn}</p>
                                    </div>
                                    <p class="played-tol">{i.Mork}</p>
                                    <p class="played-tol">{i.Viti}</p>
                                    <p class="played-tol">{i.Leikir}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                : states.selectedFlokkur == "Gul_spjöld" ?
                    <div>
                        <div class="col-names">
                            <p class="pos hide-mobile">Staða</p>
                            <p class="lid-imgText-spjold">Leikmaður</p>
                            <p class="played-spjold">Fjöldi</p>
                        </div>
                        <div class="stada-container">
                            {gulspjold.map((i, j) => (
                                <div class="lid-cont">
                                    <p class="pos">{j+1}</p>
                                    <div class="lid-imgText-spjold">
                                        <img src={"https://bestadeildin.is/teamlogos/" + accentsTidy(i.FelagNafn.replaceAll(".","").replaceAll(" ", "_")) + ".png"}/>
                                        <p>{i.LeikmadurNafn}</p>
                                    </div>
                                    <p class="played-spjold">{i.Fjoldi}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                :
                    <div>
                        <div class="col-names">
                            <p class="pos hide-mobile">Staða</p>
                            <p class="lid-imgText-spjold">Leikmaður</p>
                            <p class="played-spjold">Fjöldi</p>
                        </div>
                        <div class="stada-container">
                            {raudspjold.map((i, j) => (
                                <div class="lid-cont">
                                    <p class="pos">{j+1}</p>
                                    <div class="lid-imgText-spjold">
                                        <img src={"https://bestadeildin.is/teamlogos/" + accentsTidy(i.FelagNafn.replaceAll(".","").replaceAll(" ", "_")) + ".png"}/>
                                        <p>{i.LeikmadurNafn}</p>
                                    </div>
                                    <p class="played-spjold">{i.Fjoldi}</p>

                                </div>
                            ))}
                        </div>
                    </div> 
                }
            </div>

        )
    }
}


export default Tolfraedi;