import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import './MediaPage.css';

import Video from '../components/TempContent/Video';
import TextComponent_1 from '../components/Text/TextComponent_1';


const MediaPage = () => {

    

    useEffect(() => {
        //window.scrollTo(0, 0);
    }, [])

    

    return (
        <div id="media-container">
            <div class="home-content">
                <div class="head-text">
                    <TextComponent_1/>
                </div>
                {/*<div class="text-2">
                    <h2>Fréttatilkynning</h2>
                    <p>
                        <h3>Efsta deild í knattspyrnu fær nafnið Besta deildin</h3>
                        Í dag var kynnt nýtt vörumerki fyrir efstu deildir karla og kvenna í knattspyrnu, og heita deildirnar nú Besta deildin. Snemma á síðasta ári tók Íslenskur Toppfótbolti þá ákvörðun að selja ekki lengur nafnarétt deildarinnar heldur byggja upp eigið vörumerki. Er þar verið að fylgja eftir þeirri þróun sem hefur átt sér stað í Evrópu þar sem flestar deildir eru hættar að kenna sig við eitt vörumerki en eru þess í stað með nokkra samstarfsaðila. 
                        <br/><br/>
„Með þessu móti getum við skapað okkur sterkt vörumerki til lengri tíma og þurfum ekki að hafa áhyggjur af því að byggja upp nýtt vörumerki frá grunni ef nafnarétthafi vill slíta samstarfinu. Með þessu nýja fyrirkomulagi geta samstarfsaðilar komið og farið en vörumerkið heldur velli. Þetta gefur okkur einnig tækifæri á að sækja meiri fjármuni inn í hreyfinguna en með fyrra fyrirkomulagi“ segir Birgir Jóhannsson framkvæmdastjóri ÍTF.

                    </p>
                </div>
                <div class="text-2">
                    <p>
                        <h3>Vörumerkið á rætur sínar að rekja í íslenska knattspyrnusögu</h3>
                        Nýja vörumerkið er byggt á merki sem grafið var í fyrsta bikar Íslandsmótsins frá árinu 1912 og hefur sterka tengingu við sögu Íslandsmótsins.
                        <br/><br/>
„Þegar við vorum í þeim rannsóknarfasa að skoða söguna kom ég auga á þennan bikar enda mjög áberandi á öllum myndum frá fyrstu árum Íslandsmótsins. Mér fannst bikarinn mjög flottur og öll sagan í kringum tilurð hans heillaði mig. Þegar ég komst svo að því að hann hefði verið varðveittur og sá þetta merki grafið í hann fannst mér mjög spennandi að reyna að nota það í einhverri mynd við sköpunina á nýju vörumerki. Ég fór strax að hugsa hvernig þetta merki myndi líta út í dag hefði það fengið að þróast öll þessi ár en bikarinn fagnar einmitt 110 ára afmæli í ár. Úr varð að við einblíndum á fálkann eftir að ég komst að því að skjaldamerki Íslands á þessum tíma hefði verið hvítur fálki á bláum bakgrunni. Merkið er upprunanlega samansett úr mynd af Íslandi og fálka sem er umvafið lárviðarkransi. Fálkinn var stolt Íslendinga á þessum tíma og okkar helsta söluvara erlendis. Það má líkja því við þegar við seljum okkar frambærilegustu leikmenn úr Bestu deildinni erlendis“ segir Björn Þór markaðsstjóri ÍTF

                    </p>
                </div>
                <div class="text-2">
                    <p>
                        <h3>Nafnið einnig tekið úr sögu bikarsins</h3>
                        Fram vann fyrsta opinbera kappleikinn á Íslandi árið 1911 en á þeim tíma var ekki veittur bikar. Frömurum fannst nauðsynlegt að keppt væri um bikar á Íslandi að erlendum sið og hófu að safna fyrir verðlaunagrip sem kostaði heilar 85 kr.  aðfluttur frá Þýskalandi. Framarar vildu að bikarinn bæri nafnið „knattspyrnubikar Íslands“ og handhafi hans hverju sinni bæri nafnbótina „besta knattspyrnulið Íslands“. 
                        <br/><br/>
„Það að finna nýtt nafn á deildina var mesti höfuðverkurinn en þegar við fundum þessa tengingu bikarsins við nafnbótina besta knattspyrnulið landsins fórum við að skoða það að kalla deildina „Besta deildin“. Þessi nafnbót var líka mjög eftirsóknarverð hér á árum áður og í gömlu blöðum er mun meiri áhersla á að lið sem vinna Íslandsmótið fái nafnbótina „besta knattspyrnulið Íslands“ heldur en verða Íslandsmeistarar. Þá var það líka kostur að auðvelt er að skilja orðið besta á öðrum tungumálum þá sérstaklega á norðulöndunum og á ensku“ segir Björn Þór  



                    </p>
    </div>*/}
                <h2 class="video-media-h2">Kynningarmyndband</h2>
                <Video/>
                <div class="myndefni-cont">
                    <div style={{marginBottom: '50px'}}>
                        <Link id="dl-link" to="/files/media_besta.zip" target="_blank" download>Smelltu hér til að sækja allt myndefni</Link>
                    </div>
                    <h2 style={{marginTop: '20px'}}>Myndefni</h2>
                    <div class="myndefni">
                        <img class="single-image"  src='images/myndefni1.png' />
                        <img class="single-image"  src='images/myndefni2.png' />
                        <img class="single-image"  src='images/myndefni3.png' />
                        {/*<img class="single-image"  src='images/image001.png' />*/}
                    </div>
                    <h2 style={{marginTop: '100px'}}>Merki</h2>
                    <div class="merki">
                        <div class="big">
                            <img class="single-image"  src='images/merki1.png' />
                        </div>
                        <div class="double">
                            <img class="single"  src='images/merki2.png' />
                            <img class="single"  src='images/merki3.png' />
                        </div>
                        <div class="double">
                            <img class="single"  src='images/merki4.png' />
                            <img class="single"  src='images/merki5.png' />
                        </div>
                        <div class="double">
                            <img class="single"  src='images/merki6.png' />
                            <img class="single"  src='images/merki7.png' />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default MediaPage;