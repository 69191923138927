import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './TextComponent.css';


const TextComponent_1 = () => {


    return (
        <div class="cont-cont">
            <div class="text-container">
                <h2>Saga Vörumerkisins</h2>
                <p>
                Vörumerkið er rætur sínar að rekja allt til upphafsára Íslandsmótsins. Knattspyrnufélagið Fram vann fyrsta opinbera kappleikinn á Íslandi árið 1911. Stuttu seinna á fundi í Fram kom upp sú tillaga að nauðsynlegt væri að keppa um bikar á Íslandi að erlendum sið. Hófu þá Framarar að safna fyrir bikar sem keyptur var frá Þýskalandi á 85 kr. Grafið í bikarinn var merki, mynd af Íslandi og fálka umvafið lárviðarkransi. Nýja merki Bestu deildar sækir innblástur sinn í fálkann á merkinu á fyrsta bikarnum. Skjaldamerki Ísland var hvítur fálki á bláum bakgrunni á þeim árum sem bikarinn var framleiddur sem útskýrir líklega veru hans þar. Nafnið á Bestu deildinni er einnig sótt í sögu bikarsins. Framarar vildu að nafn bikarsins væri knattspyrnubikar Íslands og handhafi hans hverju sinni bæri nafnbótina „Besta knattspyrnulið Ísland. Í blaðagreinum frá þessum árum má sjá að það þótti eðlilegra að tala um lið hafi hlotið nafnbótina „Besta knattspyrnulið landsins” heldur en að lið hafi orðið Íslandsmeistari. Því til stuðnings má sjá ritað á forsíðu dagblaðsins Vísis frá 1930, Úrslitakappleikurinn um nafnbótina „Besta knattspyrnulið landsins” fer fram í kveld kl. 8 ½ á Íþróttavellinum.
                </p>
            </div>
        </div>
        )
    }


export default TextComponent_1;

