import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatsDropDown from '../StatsDropDown/StatsDropDown';

import './Umferd.css';


const Umferd = () => {

    const states = useSelector(state => state);
    const [currentUmferd, setCurrentUmferd] = useState([]);
    const [links, setLinks] = useState({});

    useEffect(() => {
        setCurrentUmferd(states.umferdir);
    }, [states.umferdir]);

    useEffect(() => {
        if(states.umferd == 'Allar umferðir') {
            setCurrentUmferd(states.umferdir);
        } else {
            setCurrentUmferd([states.umferd]);
        }
    }, [states.umferd]);

    useEffect(() => {
        let ignore = false;
        const data = fetch('https://bestadeildin.is/dashboard/tickets/get_all_youtube_links',{
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
                if (!ignore) {
                    let temp = {};
                    for(let i = 0; i < result.length; i++) {
                        temp[result[i].gameId] = {'youtube': result[i].Link, 'stats': result[i].StatsImageUrl, 'optaGameId': result[i].optaGameId};
                    }
                    //console.log(temp);
                    setLinks(temp);
                }
            },
            (error) => {
                console.log(error);
            }
        )
        return () => {
          ignore = true;
        };
    }, []);

    const formatStrToDate = (str) => {
        var days = ['Sunnudagur','Mánudagur','Þriðjudagur','Miðvikudagur','Fimmtudagur','Föstudagur','Laugardagur'];
        var months = ['janúar','febrúar','mars','apríl','maí','júní','júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];
        var date = new Date(str);
        var day = days[date.getDay()];
        var month = months[date.getMonth()];
        return day + " " + (date.getDate()) + ". " + month;
    }

    const formatStrToTime = (str) => {
        var date = new Date(str);

        if(date.getMinutes() == '0') {
            return date.getHours() + ":" + date.getMinutes() + '0';
        } else {
            return date.getHours() + ":" + date.getMinutes();
        }
    }

    if(states.games && Object.keys(states.games).length === 0 && Object.getPrototypeOf(states.games) === Object.prototype) {
        return (
            <div></div>
        )
    } else {
        return (
            <div class="umferd-cont">
                {currentUmferd.map((umferd) => {
                    return(
                        <div>
                            <h2>{umferd + ". umferð"}</h2>
                            <div class="umferd-container">
                                {states.games.ArrayMotLeikir.MotLeikur.map((game, n) => {
                                    let referenceId = game?.LeikurNumer;
                                    let youtube = links[referenceId]?.youtube;
                                    let stats = links[referenceId]?.stats;
                                    let optaGameId = links[referenceId]?.optaGameId;
                                    if(game.UmferdNumer == umferd) {
                                        return(
                                            <div style={{width: '100%'}}>
                                                <div style={game.UmferdNumer == umferd && states.pickedTeam == 'Öll lið' ? {width: '100%', borderBottom: '1px solid #E5E5E5'}: {width: '100%'}}>
                                                    {game.UmferdNumer == umferd && states.pickedTeam == 'Öll lið' ?
                                                    <div class="umferd-game">
                                                        <div className="umferd-game-info">
                                                            <div className="umferd-game-details">
                                                                <p class="umferd-date">{formatStrToDate(game.LeikDagur)}</p>
                                                                <div style={{display: "flex",flexDirection: "column",alignItems: "center", /*width: "33.3%"*/}}>
                                                                    <div class="teamsTime">
                                                                        <div class="lid-left umferd-lid">
                                                                            <p>{game.FelagHeimaNafn}</p>
                                                                            <img src={"https://bestadeildin.is/teamlogos/" + game.FelagHeimaNumer + ".png"} />
                                                                        </div>
                                                                        {game.UrslitHeima == "" ? 
                                                                            <p class="umferd-time">{formatStrToTime(game.LeikDagur)}</p>
                                                                        :
                                                                            <p class="umferd-time">{game.UrslitHeima + " - " + game.UrslitUti}</p>
                                                                        }
                                                                        <div class="lid-right umferd-lid">
                                                                            <img src={"https://bestadeildin.is/teamlogos/" + game.FelagUtiNumer + ".png"} />
                                                                            <p>{game.FelagUtiNafn}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="umferd-location">{game.VollurNafn}</p>
                                                            </div>
                                                            <StatsDropDown index={n} statsUrl={stats} optaGameId={optaGameId} youtube={youtube} links={links} referenceId={referenceId} stats={stats} game={game} />
                                                        </div>
                                                    </div>
                                                    : game.UmferdNumer == umferd && (states.pickedTeam == game.FelagHeimaNafn || states.pickedTeam == game.FelagUtiNafn) ?
                                                    <div class="umferd-game">
                                                        <p class="umferd-date">{formatStrToDate(game.LeikDagur)}</p>
                                                        <div style={{display: "flex",flexDirection: "column",alignItems: "center", width: "33.3%"}}>
                                                            <div class="teamsTime">
                                                                <div class="lid-left umferd-lid">
                                                                    <p>{game.FelagHeimaNafn}</p>
                                                                    <img src={"https://bestadeildin.is/teamlogos/" + game.FelagHeimaNumer + ".png"} />
                                                                </div>
                                                                {game.UrslitHeima == "" ? 
                                                                    <p class="umferd-time">{formatStrToTime(game.LeikDagur)}</p>
                                                                :
                                                                    <p class="umferd-time">{game.UrslitHeima + " - " + game.UrslitUti}</p>
                                                                }
                                                                <div class="lid-right umferd-lid">
                                                                    <img src={"https://bestadeildin.is/teamlogos/" + game.FelagUtiNumer + ".png"} />
                                                                    <p>{game.FelagUtiNafn}</p>
                                                                </div>
                                                            </div>
                                                            <div class="umferd-links">
                                                                <div>
                                                                    {links[referenceId] ? 
                                                                    <p style={{margin: "0",textDecoration: "underline", cursor: "pointer"}} onClick={() => {
                                                                        const newWindow = window.open(links[referenceId], '_blank', 'noopener,noreferrer')
                                                                        if (newWindow) newWindow.opener = null
                                                                    }}>Hápunktar</p>
                                                                    :
                                                                    <div></div>
                                                                    }
                                                                </div>
                                                                <p style={{margin: "0",textDecoration: "underline", cursor: "pointer"}} onClick={() => {
                                                                    const newWindow = window.open('https://www.ksi.is/mot/leikskyrsla/?leikur='+game.LeikurNumer, '_blank', 'noopener,noreferrer')
                                                                    if (newWindow) newWindow.opener = null
                                                                }}>Leikskýrsla</p>
                                                            </div>
                                                        </div>
                                                        <p class="umferd-location">{game.VollurNafn}</p>
                                                    </div>
                                                    :
                                                    null}
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            )
        }
    }


export default Umferd;

