import React, { useState, useEffect } from 'react';

const GamePage = () => {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js";
        script.async = true;
        script.
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
    }, []);
    
    const opta_settings = {
        subscription_id: 'b83fb66d61c0ab8c8a0cca7866ddb5ae',
        language: 'en_GB',
        timezone: 'Europe/London'
    };

    return (
        <div id="container">
            <div class="home-content">
                <h1>Test</h1>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {/*<div style={{width: "90%", maxWidth: '1200px'}}>
                        <opta-widget widget="season_team_stats" competition="141" season="2024" team="2280" template="normal" navigation="tabs_more" default_nav="1" show_general="true" show_distribution="true" show_attack="true" show_defence="true" show_discipline="true" team_naming="full" show_logo="true" show_title="true" breakpoints="400" sport="football"></opta-widget>
                    </div>
                    <div style={{width: "90%", maxWidth: '1200px'}}>
                        <opta-widget widget="match_summary" competition="141" season="2024" match="2343351" template="normal" live="true" show_match_header="true" show_score="true" show_attendance="true" show_date="true" date_format="dddd D MMMM YYYY HH:mm" show_cards="all" show_crests="false" show_team_formation="true" show_goals="true" show_goals_combined="false" show_penalties_missed="true" show_halftime_score="false" show_referee="true" show_subs="true" show_venue="true" show_shootouts="true" show_tooltips="false" show_images="false" show_competition_name="true" competition_naming="full" team_naming="full" player_naming="full" show_live="false" show_logo="true" show_title="true" breakpoints="400, 700" sport="football"></opta-widget>
                    </div>
                    <div style={{width: "90%", maxWidth: '1200px'}}>
                        <opta-widget widget="average_positions" competition="141" season="2024" match="2343351" template="normal" live="true" orientation="horizontal" side="both" show_match_header="true" show_halftime_score="false" show_crests="false" show_pitch_crests="false" show_graphics="all" default_nav="1" show_images="false" show_subs="true" show_competition_name="true" show_date="true" date_format="dddd D MMMM YYYY" show_referee="true" show_venue="true" show_attendance="true" show_team_sheets="false" subs_selected="false" competition_naming="full" team_naming="full" player_naming="full" show_logo="true" show_title="true" breakpoints="400, 700" sport="football"></opta-widget>
    </div>*/}
                    <div style={{width: "90%", maxWidth: '1200px'}}>
                        <opta-widget widget="action_areas" competition="141" season="2024" match="2418939" template="normal" live="false" animation="false" show_match_header="true" show_crests="false" show_pitch_crests="false" show_competition_name="true" competition_naming="full" show_date="true" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" team_naming="full" show_timecontrols="true" show_title="true" show_logo="true" show_live="false" breakpoints="400, 700" sport="football"></opta-widget>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GamePage;