import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Lid.css';

const accentsTidy = function(s){
    var r=s.toLowerCase();
    r = r.replace(new RegExp(/\s/g),"");
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/æ/g),"ae");
    r = r.replace(new RegExp(/þ/g),"th");
    r = r.replace(new RegExp(/ð/g),"d");
    r = r.replace(new RegExp(/ç/g),"c");
    r = r.replace(new RegExp(/[èéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñ/g),"n");                
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/\W/g),"");
    return r;
};

const LidNavbar = () => {

    const [men, setMen] = useState([]);
    const [women, setWomen] = useState([]);
    const [teams, setTeams] = useState([]);

    const states = useSelector(state => state);

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch('https://bestadeildin.is/API/felog/bestadeild_karla.php',{
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                setMen(result.MotStadaSvar.ArrayMotStada.MotStada);
            },
            (error) => {
                console.log(error);
            }
        )

        fetch('https://bestadeildin.is/API/felog/bestadeild_kvenna.php',{
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                setWomen(result.MotStadaSvar.ArrayMotStada.MotStada);
            },
            (error) => {
                console.log(error);
            }
        )

    }, []);

    const teamss = men.concat(women);

    const PopulateTeamsState = (arr) => {
        let mapObj = new Map()
            
            arr.forEach(v => {
              let prevValue = mapObj.get(v.FelagNumer)
              if(!prevValue){
                mapObj.set(v.FelagNumer, v)
              } 
            })
            return [...mapObj.values()]
    }

    useEffect(() => {
        if(women.length > 0 && men.length > 0) {
            setTeams(PopulateTeamsState(teamss));
        }
    }, [women, men])


    if(states.games && Object.keys(states.games).length === 0 && Object.getPrototypeOf(states.games) === Object.prototype) {
        return (
            <div></div>
        )
    } else {
        return (
            <div class="lid-nav-container">
                {teams.map(i => {
                    return(
                        <div class="" onClick={() => {
                            if(i.FelagNafn == "Leiknir R.") {
                                const newWindow = window.open('https://www.leiknir.com', '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            } else if(i.FelagNafn == "Víkingur R.") {
                                const newWindow = window.open('https://www.vikingur.is', '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            } else if(i.FelagNafn == "Þróttur R.") {
                                const newWindow = window.open('https://www.trottur.is', '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            } else if(i.FelagNafn == "Selfoss") {
                                const newWindow = window.open('https://www.selfoss.net/knattspyrna', '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            } else {
                                const newWindow = window.open('https://www.'+accentsTidy(i.FelagNafn)+'.is', '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            }
                        }}>
                            <img className='lid-nav-img-size' src={"https://bestadeildin.is/teamlogos/" + i.FelagNumer + ".png"}/>
                        </div>
                    )
                })}
            </div>
        )
    }
}


export default LidNavbar;

