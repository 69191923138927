import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';


const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk))) 
ReactDOM.render(<Provider store={ store }><App /></Provider>, document.getElementById('root'));

reportWebVitals();