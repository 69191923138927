import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import FantasyBanner from '../FantasyBanner/FantasyBanner';

import Logo from './Logo';
import Links from './Links';


const Navbar = () => {

    return (
        <div>
            <div class="nav-container">
                <Logo />
                <Links />
            </div>
            {/*<FantasyBanner/>*/}
        </div>
        )
    }


export default Navbar;

