import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';


const Logo = () => {


    return (
        <div class="logo">
            <img src={'/logo/besta_deildin_hvitt_logo.svg'} onClick={() => {
                window.location.href = '/';
            }}/>
        </div>
        )
    }


export default Logo;

