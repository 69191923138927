import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './SponsorBanner.css';

const SponsorBanner = () => {

    return(
        <div>
            <div className="sponsor-banner-container-mobile">
                <img src="images/sponsor-banner-mobile.png"/>
            </div>
            <div className="sponsor-banner-container">
                <img src="images/sponsor-banner.png"/>
            </div>
        </div>
    );
}

export default SponsorBanner;