import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './StadaPage.css';

import Filter from '../components/Filter/filter';
import Tolfraedi from '../components/Tolfraedi/Tolfraedi';


const StatsPage = () => {

    const dispatch = useDispatch();
    const states = useSelector(state => state);


    return (
        <div id="container">
            <div class="home-content">
                <h1>Ýtarleg tölfræði - Besta deild karla</h1>
                <div style={{width: "500px"}}>
                    <opta-widget widget="season_team_stats" competition="141" season="2023" team="2280" template="normal" navigation="tabs_more" default_nav="1" show_general="true" show_distribution="true" show_attack="true" show_defence="true" show_discipline="true" team_naming="full" show_logo="true" show_title="true" breakpoints="400" sport="football"></opta-widget>
                </div>
            </div>
        </div>
    )
}

export default StatsPage;