import React, {Fragment, useState} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import HomePage from './pages/HomePage';
import Navbar from './components/Navbar/Navbar';
import LidNavbar from './components/Lid/LidNavbar';
import Footer from './components/Footer/Footer';
import MediaPage from './pages/MediaPage';
import UrslitPage from './pages/UrslitPage';
import StadaPage from './pages/StadaPage';
import LidPage from './pages/LidPage';
import TolfraediPage from './pages/TolfraediPage';
import StatsPage from './pages/StatsPage';
import GamePage from './pages/GamePage';


function App() {

  return (
      <Router>
        <LidNavbar />
        <Navbar/>
          <Fragment>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/`} element={<HomePage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/media`} element={<MediaPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/urslit`} element={<UrslitPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/stada`} element={<StadaPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/lid`} element={<LidPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/tolfraedi`} element={<TolfraediPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/stats`} element={<StatsPage/>} />
          </Routes>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/game/:id`} element={<GamePage/>} />
          </Routes>
          </Fragment>
          <Footer/>
      </Router>
  );
}

export default App;

/*
<Navbar/>
<Footer/>
<Routes>
  <Route exact path="/my-team/:FPLid" element={<MyTeamPage/>} />
</Routes>
*/