import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './StadaPage.css';

import Filter from '../components/Filter/filter';
import Stada from '../components/Stada/Stada';


const HomePage = () => {

    const dispatch = useDispatch();
    const states = useSelector(state => state);

    

    return (
        <div id="container">
            <div class="home-content">
                <h1>Stöðutöflur</h1>
                <Filter filters="deild"/>
                <Stada/>
            </div>
        </div>
    )
}

export default HomePage;