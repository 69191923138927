import React, { useState, useEffect } from 'react';
import ReactPlayer from "react-player"
import { Link } from 'react-router-dom';
import './Video.css';



const Video = () => {


    return (
        <div class="temp-container">
            {/*<h1>Myndband</h1>*/}
            <div>
                <ReactPlayer
                    width={'80%'}
                    height={'calc(100vw * 0.417)'}
                    url="https://www.youtube.com/watch?v=-yFE_HoK8T8"
                />
            </div>
        </div>
        )
    }


export default Video;

