import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './LidPage.css';

import Filter from '../components/Filter/filter';
import Lid from '../components/Lid/Lid';


const HomePage = () => {

    const dispatch = useDispatch();
    const states = useSelector(state => state);

    

    return (
        <div id="container">
            <div class="home-content">
                <h1>Lið</h1>
                <Filter filters="deild"/>
                <Lid/>
            </div>
        </div>
    )
}

export default HomePage;