import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import Sponsors from './Sponsors';
import FooterItems from './FooterItems';


const Footer = () => {


    return (
        <div class="footer-container">
            <Sponsors/>
            <FooterItems/>
        </div>
        )
    }


export default Footer;

