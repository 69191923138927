import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './HomePage.css';

import Filter from '../components/Filter/filter';
import Umferd from '../components/Umferd/Umferd';
import GamesSlider from '../components/GamesSlider/GamesSlider';
import SponsorBanner from '../components/SponsorBanner/SponsorBanner';
import Clock from '../components/Countdown/Countdown';


const HomePage = () => {

    //const dispatch = useDispatch();
    //const states = useSelector(state => state);

    

    return (
        <div id="container">
            <div class="home-content">
                {/*<Clock deadline={"April, 10, 2023, 14:00"} />*/}
                <GamesSlider />
                <SponsorBanner />
                <Filter filters="allt"/>
                <Umferd/>
            </div>
        </div>
    )
}

export default HomePage;