import { combineReducers } from 'redux';
import games from './games';
import deild from './deild';
import lid from './lid';
import umferdir from './umferdir';
import umferd from './umferd';
import pickedTeam from './pickedTeam';
import gengiLida from './gengiLida';
import selectedFlokkur from './selectedFlokkur';
import markahaestu from './markahaestu';
import allGames from './allGames';


export default combineReducers({
    games,
    deild,
    lid,
    umferdir,
    umferd,
    pickedTeam,
    gengiLida,
    selectedFlokkur,
    markahaestu,
    allGames
});
