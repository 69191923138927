import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


const Sponsors = () => {


    return (
        <div class="sponsor-cont">
            <div class="sponsors-container">
                <div class="sponsor">
                    <img src='logo/steypustodin.svg' />
                </div>
                <div class="sponsor">
                    <img src='logo/lengjan.png' />
                </div>
                <div class="sponsor">
                    <img src='logo/eittsett.svg' />
                </div>
                <div class="sponsor">
                    <img src='logo/Unbroken_logo_final_black.png' />
                </div>
            </div>
        </div>
        )
    }


export default Sponsors;

