import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';


const Links = () => {

    const [path, setPath] = useState(window.location.pathname);

    const location = useLocation();

    const updatePath = (path) => {
        setPath(path);
    }

    useEffect(() => {
        if(location) {
            updatePath(location.pathname);
        }
    }, [location])

    /*useEffect(() => {
        console.log("path in links");
        console.log(path);
    }, [path]);*/

    const changeRoutePath = (e) => {
        e.preventDefault();
        console.log(e);
        //setPath(this.props.match.path);
    }

    return (
        <div>
            <div className="links-container">
                <div id="leikir" class="links">
                    <Link to="/" style={path == "/" ? {color: '#1EB6FF'} : null}>Leikir</Link>
                    <div style={path == "/" ? {display: "inline-block"} : null} id="leikir-dot" class="dot-active"></div>
                </div>
                {/*<div id="urslit" class="links">
                    <a href={"/urslit"} style={path == "/urslit" ? {color: '#160259'} : null}>Úrslit</a>
                    <div style={path == "/urslit" ? {display: "inline-block"} : null} id="urslit-dot" class="dot-active"></div>
                </div>*/}
                <div id="stada" class="links">
                    <Link to="/stada" style={path == "/stada" ? {color: '#1EB6FF'} : null}>Stöðutöflur</Link>
                    <div style={path == "/stada" ? {display: "inline-block"} : null} id="stada-dot" class="dot-active"></div>
                </div>
                <div id="lid" class="links">
                    <Link to="/lid" style={path == "/lid" ? {color: '#1EB6FF'} : null}>Lið</Link>
                    <div style={path == "/lid" ? {display: "inline-block"} : null} class="dot-active"></div>
                </div>
                <div id="tolfraedi" class="links">
                    <Link to="/tolfraedi" style={path == "/tolfraedi" ? {color: '#1EB6FF'} : null}>Tölfræði</Link>
                    <div style={path == "/tolfraedi" ? {display: "inline-block"} : null} id="tolfraedi-dot" class="dot-active"></div>
                </div>
                <div id="media" class="links">
                    <Link to="/media" style={path == "/media" ? {color: '#1EB6FF'} : null}>Fjölmiðlar</Link>
                    <div style={path == "/media" ? {display: "inline-block"} : null} id="media-dot" class="dot-active"></div>
                </div>
                <div class="fantasy-cont-desk" onClick={() => {
                    window.open('https://fantasy.bestadeildin.is/', '_blank', 'noopener,noreferrer')
                }}>
                    <p style={{margin: "0"}}>Fantasy</p>
                    <img style={{marginTop: "6px"}} class="fantasy-fantasy" src="https://fantasy.bestadeildin.is/static/media/footballFantasyWrapped.902bdca3d67e542984f7d2cf7b298717.svg" />
            </div>
            </div>
            <div class="links-container-mobile">
                <div class="fantasy-cont" onClick={() => {
                    window.open('https://fantasy.bestadeildin.is/', '_blank', 'noopener,noreferrer')
                }}>
                    <p style={{color: '#0000B8', fontSize: '12px', fontWeight: 'bold'}}>Fantasy</p>
                    <img style={{marginTop:'5px', marginLeft: '-5px'}} class="fantasy-fantasy" src="https://fantasy.bestadeildin.is/static/media/footballFantasyWrapped.902bdca3d67e542984f7d2cf7b298717.svg" />
                </div>
                <img style={{height: '12px'}} src="logo/burger_light.png" onClick={() => {
                    if(document.getElementById('mobile-dropdown').style.display == "none") {
                        document.getElementById('mobile-dropdown').style.display = "flex";
                    } else {
                        document.getElementById('mobile-dropdown').style.display = "none";
                    }
                }}/>
                <div style={{display: 'none'}} id="mobile-dropdown" class="nav-dropdown-links">
                    <a href={"/"}><p>Leikjaplan</p></a>
                    <a href={"/stada"}><p>Stöðutöflur</p></a>
                    <a href={"/lid"}><p>Lið</p></a>
                    <a href={"/tolfraedi"}><p>Tölfræði</p></a>
                    <a href={"/media"}><p>Fjölmiðlar</p></a>
                </div>
            </div>
        </div>
        )
    }


export default Links;

