import React, { useState, useEffect } from 'react';

const StatsDropDown = (props) => {

    const [selectedStat, setSelectedStat] = useState('');
    //const [selectedStat, setSelectedStat] = useState(props.statsUrl ? 'besta-stats' : 'opta-match-summary');

    const updateSelectedStat = (str) => {
        setSelectedStat(str);
    }

    return (
        <div id={"umferd-game-"+props.index} className="umferd-game-dropdown">
            <div style={{marginBottom: '15px'}} class="umferd-links">
                <div>
                    {props.youtube ? 
                    <p style={{margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}} onClick={() => {
                        const newWindow = window.open(props.links[props.referenceId]['youtube'], '_blank', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                    }}>Hápunktar</p>
                    :
                    <div></div>
                    }
                </div>
                <p onClick={() => {
                    updateSelectedStat('besta-stats');
                    //window.open('/game/'+game.LeikurNumer);
                }} style={selectedStat == 'besta-stats' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Yfirlit</p>
                <p onClick={() => {
                    window.open('https://stats.bestadeildin.is/games/'+props?.game?.LeikurNumer, '_blank');
                }} style={selectedStat == 'besta-stats' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Tölfræði</p>
                {/*<p style={{margin: "0", cursor: "pointer"}} onClick={() => {
                    const newWindow = window.open('https://www.ksi.is/mot/leikskyrsla/?leikur='+props.game.LeikurNumer, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                }}>Leikskýrsla</p>*/}
                {/*<p onClick={() => {
                    updateSelectedStat('besta-stats');
                }} style={selectedStat == 'besta-stats' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Yfirlit</p>
                <p onClick={() => {
                    updateSelectedStat('opta-match-summary');
                }} style={selectedStat == 'opta-match-summary' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Leikskýrsla</p>
                <p onClick={() => {
                    updateSelectedStat('opta-avg-pos');
                }} style={selectedStat == 'opta-avg-pos' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Staðsetningar</p>
                <p onClick={() => {
                    updateSelectedStat('opta-action-areas');
                }} style={selectedStat == 'opta-action-areas' ? {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: '#00F7AA', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'} : {margin: "0", cursor: 'pointer', marginRight: '10px', backgroundColor: 'white', borderRadius: '20px', color: '#160259', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px', fontSize: '14px'}}>Action areas</p>*/}
            </div>
            <div className={selectedStat == 'besta-stats' ? "statsDropDownItem" : "hidden"}>
                <img className="umferd-game-statImg" src={props.statsUrl} />
                <p className="absoluteClose" onClick={() => {
                    updateSelectedStat('');
                }}>Loka</p>
            </div>
            {/*<div className={selectedStat == 'opta-match-summary' ? "statsDropDownItem" : "hidden"}>
                <div className="opta-widget-display" style={selectedStat == 'opta-match-summary' ? {visibility: 'visible', height: '100%'} : {visibility: 'hidden', height: '0'}}>
                    <opta-widget widget="match_summary" competition="141" season="2023" match={props.optaGameId} template="normal" live="true" show_match_header="true" show_score="true" show_attendance="true" show_date="true" date_format="dddd D MMMM YYYY HH:mm" show_cards="all" show_crests="false" show_team_formation="true" show_goals="true" show_goals_combined="false" show_penalties_missed="true" show_halftime_score="false" show_referee="true" show_subs="true" show_venue="true" show_shootouts="true" show_tooltips="false" show_images="false" show_competition_name="true" competition_naming="full" team_naming="full" player_naming="full" show_live="false" show_logo="true" show_title="true" breakpoints="400, 700" sport="football"></opta-widget>
                </div>
                <p className="absoluteClose" onClick={() => {
                    updateSelectedStat('');
                }}>Loka</p>
            </div>
            <div className={selectedStat == 'opta-avg-pos' ? "statsDropDownItem" : "hidden"}>
                <div className="opta-widget-display" style={selectedStat == 'opta-avg-pos' ? {visibility: 'visible', height: '100%'} : {visibility: 'hidden', height: '0'}}>
                    <opta-widget widget="average_positions" competition="141" season="2023" match={props.optaGameId} template="normal" live="true" orientation="horizontal" side="both" show_match_header="true" show_halftime_score="false" show_crests="false" show_pitch_crests="false" show_graphics="all" default_nav="1" show_images="false" show_subs="true" show_competition_name="true" show_date="true" date_format="dddd D MMMM YYYY" show_referee="true" show_venue="true" show_attendance="true" show_team_sheets="false" subs_selected="false" competition_naming="full" team_naming="full" player_naming="full" show_logo="true" show_title="true" breakpoints="400, 700" sport="football"></opta-widget>
                </div>
                <p className="absoluteClose" onClick={() => {
                    updateSelectedStat('');
                }}>Loka</p>
            </div>
            <div className={selectedStat == 'opta-action-areas' ? "statsDropDownItem" : "hidden"}>
                <div className="opta-widget-display" style={selectedStat == 'opta-action-areas' ? {visibility: 'visible', height: '100%'} : {visibility: 'hidden', height: '0'}}>
                    <opta-widget widget="action_areas" competition="141" season="2023" match={props.optaGameId} template="normal" live="false" animation="false" show_match_header="true" show_crests="false" show_pitch_crests="false" show_competition_name="true" competition_naming="full" show_date="true" date_format="dddd D MMMM YYYY" team_naming="full" show_timecontrols="true" show_title="true" show_logo="true" show_live="false" breakpoints="400, 700" sport="football"></opta-widget>
                </div>
                <p className="absoluteClose" onClick={() => {
                    updateSelectedStat('');
                }}>Loka</p>
            </div>*/}
        </div>
    )
}

export default StatsDropDown;